@import "./variables.css";

.contacts {
    composes: sectionContents from "./Shared.css";
    composes: overlayBackground from "./Shared.css";
    position: relative;
    overflow: hidden;
    color: white;
}

.wrapper {
    composes: section from "./Shared.css";
    composes: boxedSection from "./Shared.css";
}

.contents {
    composes: section from "./Shared.css";
    z-index: 2;
    grid-template:
        "info" auto
        "map" auto
        "form" auto
        / 1fr;
    padding: 0;

    @media (--mobile) {
        & {
            grid-gap: 2x 5x;
            grid-template:
                "info map " auto
                "info form" auto
                / 2fr 5fr;
        }
    }

    @media (--tablet) {
        & {
            grid-template:
                "info map form" auto
                / 1fr 2fr 2fr;
        }
    }
}

.infoSection {
    grid-area: info;

    @media (--tablet) {
        & {
            margin-top: 1x;
        }
    }
}

.infoItem {
    display: grid;
    grid-gap: 0 1x;
    grid-template:
        "icon label" auto
        "icon value" auto
        / auto 1fr;
    align-content: center;
    margin-bottom: 1x;
    line-height: 1.2;
}

.infoIcon {
    grid-area: icon;
    font-size: 1.5rem;
}

.infoLabel {
    grid-area: label;
}

.infoValue {
    grid-area: value;
    font-weight: bold;
}

.mapSection {
    grid-area: map;

    @media (--tablet) {
        & {
            margin-top: 2x;
        }
    }
}

.map {
    width: 100%;
    background-color: #fff;

    & iframe {
        width: 100%;
        height: 200px;
    }

    @media (--table) {
        & iframe {
            height: 250px;
        }
    }

    @media (--mobile) {
        & iframe {
            height: 300px;
        }
    }
}

.formSection {
    grid-area: form;

    @media (--tablet) {
        & {
            margin-top: -1.5x;
        }
    }
}

.fields {
    display: grid;
    grid-gap: 0 2x;
    grid-template-columns: auto;

    @media (--mobile) {
        & {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

.field {
    &, & > * {
        display: block;
    }
}

.label {
    padding: .5x 1x;
    font-weight: bold;
    font-size: .9rem;
}

.baseInput {
    color: black;
    font-size: 1rem;
    font-family: inherit;
    background-color: rgba(255, 255, 255, .8);
    border: .1x solid lightgrey;
    border-radius: .5x;
}

.input {
    composes: baseInput;
    width: 100%;
    max-width: 100%;
    padding: 1x;
    resize: none;
}

.fieldWide {
    composes: field;

    @media (--mobile) {
        & {
            grid-column: span 2;
        }
    }
}

.formMessage {
    padding: 1x 3x;
    font-size: 110%;
}

.buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.button {
    composes: baseInput;
    margin: 1.5x 0;
    padding: 1x 3x;
    background-color: lightgrey;

    &:hover {
        background-color: dimgrey;
    }

    &:active {
        background-color: lightgrey;
    }
}
