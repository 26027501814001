@import "./variables.css";

.departments {
    composes: overlayBackground from "./Shared.css";
    composes: sectionContents from "./Shared.css";
    position: relative;
    overflow: hidden;
    color: white;
}

.contents {
    composes: section from "./Shared.css";
    composes: boxedSection from "./Shared.css";
    justify-items: center;

    @media (--mobile) {
        & {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

.department {
    z-index: 2;
    width: 100%;
    max-width: 65x;
    text-align: center;
}

.title {
    font-size: 1.5rem;
    line-height: 1;
    text-align: center;
}
