@import "./variables.css";

:root {
    --Header-opacity: 1;
    --Header-nav-opacity: 1;
    --Header-logo-scale: 1;
    --Header-nav-translateY: 0;
    --Header-nav-padding: 0;
}

.horizontalFlow {
    display: flex;
    flex-flow: row;
    align-items: center;
}

.panelSurface {
    &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        background-color: var(--terziary-background-color);
        content: "";
        will-change: opacity;
    }
}

.header {
    composes: panelSurface;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    padding: 1x 2x;
    color: var(--terziary-text-color);

    &::before {
        opacity: var(--Header-opacity);
    }

    @media (--mobile) {
        & {
            padding: 2x 4x 2x 6x;
        }
    }

    @media (--tablet) {
        & {
            padding: * 8x * 10x;
        }
    }
}

.wrapper {
    composes: horizontalFlow;
    composes: boxedSection from "./Shared.css";
    justify-content: space-between;

    @media (--tablet) {
        & {
            padding: * 7x;
        }
    }
}

.logoWrapper {
    animation: fadeDown .6s 1s;
    animation-fill-mode: both;
    will-change: padding;

    @media (--toMobile) {
        margin: * auto;
        padding: var(--Header-nav-padding);
    }
}

.logo {
    display: block;
    size: 5x;
    background-color: white;
    background-image: url("../img/original.png");
    background-size: contain;
    border-radius: 50%;
    transform: scale(var(--Header-logo-scale));
}

.nav {
    composes: horizontalFlow;
    composes: panelSurface;
    justify-content: space-around;
    animation: fadeUp .6s 1.1s;
    animation-fill-mode: both;
    will-change: padding;

    @media (--mobile) {
        padding: var(--Header-nav-padding) 0;
        animation-name: fadeDown;
        animation-delay: 1s;
    }

    @media (--desktop) {
        &:hover .item, &:focus .item, &:focus-within .item {
            opacity: .6;

            &:hover, &:focus {
                opacity: 1;
            }
        }

        &::before {
            display: none;
        }
    }

    @media (--toMobile) {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;

        &::before {
            opacity: var(--Header-nav-opacity);
        }
    }
}

.item {
    composes: horizontalFlow;
    flex-flow: column;
    justify-content: center;
    padding: 1x;
    color: var(--terziary-text-color);
    font-weight: bold;
    font-family: "Quicksand", sans-serif;
    text-decoration: none;
    transition: opacity .2s;

    &:active {
        color: grey;
    }

    @media (--toMobile) {
        z-index: 100;
        flex: 1;
        font-size: .8rem;
        line-height: 1;

        &:active {
            & .itemIcon {
                transform: translateY(.2x);
            }

            & .itemLabel {
                transform: translateY(-.2x);
            }
        }
    }
}

.transformTransition {
    transition: transform .1s;
}

.itemIcon {
    composes: transformTransition;
    font-size: 1.5rem;

    @media (--desktop) {
        display: none;
    }
}

.itemLabel {
    composes: transformTransition;
    display: block;
}

@keyframes fadeUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeDown {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}
