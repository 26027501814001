@import "./variables.css";

:root {
    --Section-opacity: 1;
}

.section {
    display: grid;
    grid-gap: 3x;
    grid-template-columns: auto;
    padding: 4x;

    @media (--toMobile) {
        & {
            padding: * 3x;
        }
    }

    @media (--tablet) {
        & {
            padding: 6x 8x;
        }
    }
}

.boxedSection {
    max-width: 1400px;
    margin: * auto;
}

.sectionContents {
    opacity: var(--Section-opacity);
    will-change: opacity;
}

.sectionTitle {
    composes: sectionContents;
    z-index: 2;
    font-size: 2rem;
    line-height: 1;
    text-align: center;
}

.jumper {
    display: block;
    margin-top: -12x;
    padding-bottom: 12x;
}

.parallaxBase {
    position: absolute;
    top: -20vh;
    right: 0;
    bottom: -20vh;
    left: 0;
    z-index: -1;
}

.parallax {
    composes: parallaxBase;
    transform: translateY(var(--Parallax-translateY, 0));
    transition: transform .1s;
    will-change: transform;
}

.parallaxImage {
    composes: parallaxBase;
    background-color: lightgrey;
    background-image: var(--Parallax-image);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.overlayBackground {
    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        display: block;
        background-color: rgba(0, 0, 0, .4);
        content: "";
    }
}
