@import "./variables.css";

.footer {
    composes: section from "./Shared.css";
    padding: 2x *;
    color: var(--terziary-text-color);
    text-align: center;
    background-color: var(--terziary-background-color);

    @media (--toMobile) {
        padding-bottom: 8x;
    }
}

.notice {
    font-weight: bold;
}
