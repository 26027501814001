/* TODO: Fix custom-medias names */
@custom-media --mobile (min-width: 640px);

@custom-media --toMobile (max-width: 641px);

@custom-media --tablet (min-width: 1001px);

@custom-media --toTablet (max-width: 1000px);

@custom-media --desktop (min-width: 641px);
