@import "./variables.css";

.gallery {
    composes: sectionContents from "./Shared.css";
    color: var(--primary-text-color);
    background-color: var(--primary-background-color);
}

.wrapper {
    composes: section from "./Shared.css";
    composes: boxedSection from "./Shared.css";
}

.tiles {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1x;
    grid-template-columns: repeat(auto-fill, minmax(30x, 1fr));
    width: 100%;

    @media (--toTablet) {
        & {
            grid-template-columns: repeat(auto-fill, minmax(15x, 1fr));
        }
    }

    @media (--desktop) {
        & {
            grid-template-columns: repeat(6, 1fr);
        }
    }
}

.tile {
    position: relative;
    width: 100%;
    padding-bottom: 60%;
    overflow: hidden;
    background-color: #fff;
    border-radius: 1x;

    @media (--toTablet) {
        padding-bottom: 80%;
    }

    &:nth-child(1), &:nth-child(7) {
        @media (--desktop) {
            grid-row: span 2;
            grid-column: span 2;
        }
    }
}

.tileImage {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .5s;

    &:hover, &:active {
        z-index: 1;
        transform: scale(1.2);
    }
}
