@import "./variables.css";

.sponsors {
    composes: section from "./Shared.css";
    composes: sectionContents from "./Shared.css";
    color: var(--secondary-text-color);
    background-color: var(--secondary-background-color);
}

.items {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.item {
    width: 15x;
    height: 10x;
    margin: 1x;
    object-fit: contain;
}
