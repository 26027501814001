html {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

*, *::before, *::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
    color: var(--primary-text-color);
    font-size: 2x;
    font-family: "Lato", sans-serif;
    line-height: 1.5;
    background-color: var(--primary-background-color);
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: "Quicksand", sans-serif;
}
