@import "./variables.css";

.services {
    composes: sectionContents from "./Shared.css";
    color: var(--secondary-text-color);
    background-color: var(--secondary-background-color);
}

.wrapper {
    composes: section from "./Shared.css";
    composes: boxedSection from "./Shared.css";
}

.items {
    z-index: 2;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.serviceItem {
    width: 100%;
    padding: 4x 1x;

    @media (--desktop) {
        max-width: 50x;
        padding: 2x *;
    }
}

.serviceTitle {
    font-size: 1.2rem;
    line-height: 1;
    text-align: center;
}

.serviceDescription {
    margin-top: .5x;
}
