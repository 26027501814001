@import "./variables.css";

.intro {
    color: var(--primary-text-color);
    background-color: var(--primary-background-color);
}

.wrapper {
    composes: sectionContents from "./Shared.css";
    composes: section from "./Shared.css";
    composes: boxedSection from "./Shared.css";

    @media (--tablet) {
        & {
            grid-template-columns: 1fr 4fr;
        }
    }
}

.title {
    composes: sectionTitle from "./Shared.css";

    @media (--tablet) {
        & {
            justify-self: start;
        }
    }
}

.content {
    column-width: 65x;

    & > * {
        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
