@import "./variables.css";

.hero {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
    color: white;
    animation: fadeIn .6s .2s;
    animation-fill-mode: both;

    @media (--tablet) {
        height: 90vh;
    }
}

.content {
    z-index: 2;
    padding: 0 6x;
    text-align: center;
    animation: fadeIn .5s .4s, enterUp .5s .4s;
    animation-fill-mode: both;
}

.heading {
    display: block;
    margin: 0;
}

.title {
    composes: heading;
    font-size: 10vmin;
    line-height: 1.2;
}

.subtitle {
    composes: heading;
    font-size: 5vmin;
    line-height: 1.1;
    opacity: .8;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes enterUp {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}
